/* eslint-disable */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import { AuthContext } from '../../common/types';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import Loading from './Loading';

const RouteWithGuard: React.FC<any> = ({ component: Component, ...rest }) => {
  const authContext: AuthContext = useContext(_authContext);
  const {
    appLoading, meLoading, auth, me
  } = authContext;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (appLoading || meLoading) {
          return <Loading />;
        }
        if (auth.isAnonymous || !me.partners) {
          return <Redirect to="/login" />;
        }
        return <Component {...props} {...rest} />;
      }}
    />
  );
};

export default RouteWithGuard;