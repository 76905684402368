import React, { useContext, useState, useEffect } from 'react';
import {
  Form, Input, Button, message,
} from 'antd';
import { Redirect } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from '../../common/types';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import Loading from './Loading';

const Login = () => {
  const authContext: AuthContext = useContext(_authContext);
  const initAuth = authContext.initAuth!;
  const getSignInEmailLink = authContext.getSignInEmailLink!;
  const [signInEmailLink, setSignInEmailLink] = useState<string>();
  const [linkLoading, setLinkLoading] = useState<boolean>(false);

  const {
    auth, appLoading, authInit, isSignIn,
  } = authContext;
  const [emailForm] = Form.useForm();

  useEffect(() => {
    if (!authInit) {
      initAuth();
    }
  }, []);

  const submitEmail = () => {
    emailForm
      .validateFields()
      .then(async (value) => {
        setLinkLoading(true);
        const response = await getSignInEmailLink(value);
        setLinkLoading(false);
        setSignInEmailLink(response.signInLinkEmail);
      })
      .catch(() => {
        message.error('Validation failed');
      });
  };

  return (
    <>
      {
        isSignIn || appLoading ? <Loading /> : (
          <div className="login-container">
            <img
              className="login-logo"
              src={`${process.env.PUBLIC_URL}/assets/logo.png`}
              alt="logo"
            />
            {
              !signInEmailLink ? (
                <Form
                  form={emailForm}
                  name="sign-in-form"
                  hideRequiredMark
                  style={{ display: 'flex' }}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter email to log in" />
                  </Form.Item>
                  <Button onClick={() => submitEmail()} loading={linkLoading}>Submit</Button>
                </Form>
              ) : 'If the email you entered is associated with an account, you will receive an email with a link to log in.'
            }
            {signInEmailLink && (
              <>
                {/* <div>(For Dev Purposes, The Link Is Shown Here)</div> */}
                <a href={signInEmailLink}>Click Here To Log In</a>
              </>
            )}
          </div>
        )
      }
      {auth && !auth.isAnonymous ? <Redirect to="/" /> : null}
    </>
  );
};

export default Login;
