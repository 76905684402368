import React from 'react';

import { Collapse, Descriptions } from 'antd';

interface Props {
  header: string,
  items: {
    label: string,
    text?: string
  }[],
  defaultCollapsed?: boolean
}

const DetailPanel = ({ header, items }: Props) => (
  <div className="index-detail-panel">
    <Collapse
      defaultActiveKey={[]}
    >
      <Collapse.Panel header={header} key="1">
        <Descriptions
          bordered
          column={{
            xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1,
          }}
        >
          {items.map((item) => (
            <Descriptions.Item
              key={item.label}
              label={item.label}
            >
              {item.text}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  </div>
);

export default DetailPanel;
