import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
// https://29f49d75a32d44a8b045f1981b284c62@o419715.ingest.sentry.io/5337928
const SENTRY_KEY = '29f49d75a32d44a8b045f1981b284c62';
const SENTRY_ORG = 'o419715';
const SENTRY_PROJECT = '5337928';

if (process.env.NODE_ENV !== 'test' || window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: `https://${SENTRY_KEY}@${SENTRY_ORG}.ingest.sentry.io/${SENTRY_PROJECT}`,
    environment: process.env.REACT_APP_STAGE,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
