/* eslint no-console: "off" */
import React, { useState, useEffect, useContext } from 'react';

import { Radio, Button } from 'antd';

import ScheduleListTab from './schedule-list-tab';
import MenuTab from './menu-tab';
import { Partner, AuthContext as AuthContextType } from '../../common/types';
import AuthContext from '../../contexts/auth/authContext';
import * as apiClient from '../../common/api-client';
import { onClickSquareConnect } from '../square-oauth';

enum Section { SCHEDULE, MENU }

interface Props {
  partnerId: string,
  defaultTab: string,
  location: any,
  history: any,
}

const getTabName = (section: Section): string => {
  switch (section) {
    case Section.SCHEDULE:
      return 'Schedule';
    case Section.MENU:
      return 'Menu';
    default:
      return '';
  }
};

// const stripHtml = (content) => {
//   const doc = new DOMParser().parseFromString(content, 'text/html');
//   return doc.body.textContent || '';
// };

const PartnerTab = ({ partnerId, location, history }: Props) => {
  const authContext = useContext<AuthContextType>(AuthContext);
  const [partner, setPartner] = useState<Partner>();

  const urlParams = new URLSearchParams(location.search);
  const [selectedSection, setSelectedSection] = useState<Section>(
    parseInt(urlParams.get('t') || '', 10) ? Section.MENU : Section.SCHEDULE,
  );

  const loadPartner = async () => {
    try {
      const data = await apiClient.getPartnerById(authContext.auth, partnerId);
      setPartner(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => { loadPartner(); }, [partnerId]);

  // const detailItems = [
  //   { label: 'Meal Times', text: partner.food?.join(', ') },
  //   { label: 'Categories', text: partner.food_category?.join(', ') },
  //   { label: 'Address', text: partner.address },
  //   { label: 'Phone', text: partner.phone },
  //   { label: 'Description', text: stripHtml(partner.description) },
  // ];

  if (!partner) {
    return <section />;
  } if (!partner.merchant || !partner.merchant.id) {
    return (
      <section className="index-partner-tab-square-connect">
        <Button
          type="primary"
          size="large"
          onClick={() => onClickSquareConnect(partner.id)}
        >
          Connect Square
        </Button>
        <p>A Square merchant account must connected before you can manage events or menus.</p>
      </section>
    );
  }

  return (
    <section className="index-partner-tab">
      {/* <div className="index-partner-tab-detail">
        <DetailPanel
          header="Details"
          items={detailItems}
        />
      </div> */}
      <nav className="partner-nav">
        <Radio.Group
          buttonStyle="solid"
          value={selectedSection}
          onChange={(e) => {
            urlParams.set('t', e.target.value);
            history.replace(`${location.pathname}?${urlParams.toString()}`);
            setSelectedSection(e.target.value);
          }}
        >
          <Radio.Button
            value={Section.SCHEDULE}
          >
            {getTabName(Section.SCHEDULE)}
          </Radio.Button>
          <Radio.Button
            value={Section.MENU}
          >
            {getTabName(Section.MENU)}
          </Radio.Button>
        </Radio.Group>
      </nav>
      {selectedSection === Section.SCHEDULE
        ? <ScheduleListTab partner={partner} />
        : <MenuTab partner={partner} reloadPartner={loadPartner} />}
    </section>
  );
};

export default PartnerTab;
