/* eslint-disable no-unused-vars */

import { API } from 'aws-amplify';
import * as firebase from 'firebase/app';
import config from '../config';
import {
  SchedulesRequestParams,
  MenuType,
  BuildingRequestParams,
  Order,
  Partner,
  Schedule,
  Menu,
  SchedulePartner,
  MenuItem,
  MenuStatus,
} from './types';

const API_NAME = 'CurbsideRestAPI';

interface Headers {
  'X-Api-Key': string;
  Authorization?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getHeaders(token?: string): Promise<Headers> {
  const headers: Headers = {
    'X-Api-Key': config.aws.API.apiKey,
  };

  // currently amplify's axios wrapper
  // does not include interceptors
  // so we add the token here

  // TODO getHeaders to not have the token passed in

  let firebaseToken;

  const { currentUser } = firebase.auth();

  if (currentUser) {
    firebaseToken = await currentUser.getIdToken();
  }

  if (firebaseToken) {
    headers.Authorization = firebaseToken;
  }
  return headers;
}
// export async function createPartnerUser(partnerId: string, sentBody: any): Promise<any> {
// console.log(sentBody);
// return API.post(API_NAME, `/partners/${partnerId}/users`, {
//   headers: await getHeaders(),
//   budy: sentBody,
// })
// }

export async function listMarkets(): Promise<any> {
  return API.get(API_NAME, '/markets', { headers: await getHeaders() });
}

export async function listSchedules(queryStringParameters: SchedulesRequestParams): Promise<any> {
  return API.get(API_NAME, '/schedules', {
    headers: await getHeaders(),
    queryStringParameters,
  });
}
export async function listBuildings(queryStringParameters: BuildingRequestParams): Promise<any> {
  return API.get(API_NAME, '/buildings', { headers: await getHeaders(), queryStringParameters });
}

export async function getPartnerById(
  auth: any,
  partnerId: string,
): Promise<Partner> {
  const partner: Partner = await API.get(API_NAME, `/partners/${partnerId}`, {
    headers: await getHeaders(auth.token),
  });

  // TODO Stop parsing menus list from items once field is working
  if (!partner.menus && partner.menu) {
    const menus: string[] = [];
    partner.menu.categories!.forEach((category) => category.items!.forEach((item) => {
      item.menus!.forEach((key) => {
        if (!menus.includes(key)) {
          menus.push(key);
        }
      });
    }));
    menus.sort();
    partner.menus = menus;
  }

  return partner;
}

export async function getPartners(auth: any): Promise<Partner[]> {
  return API.get(API_NAME, '/partners', {
    headers: await getHeaders(auth.token),
  });
}

export async function getPartnerForSchedules(
  auth: any,
  partnerId: string,
): Promise<Schedule[]> {
  return API.get(API_NAME, `/partners/${partnerId}/schedules`, {
    headers: await getHeaders(auth.token),
  });
}

export async function getSchedulePartner(
  auth: any,
  partnerId: string,
  scheduleId: number,
  showDisabled?: boolean,
): Promise<SchedulePartner> {
  return API.get(API_NAME, `/schedules/${scheduleId}/partners/${partnerId}?showDisabled=${showDisabled}`, {
    headers: await getHeaders(auth.token),
  });
}

export async function getPartnerMenus(
  auth: any,
  partnerId: string,
): Promise<Menu> {
  return API.get(API_NAME, `/partners/${partnerId}/menus`, {
    headers: await getHeaders(auth.token),
  });
}

export async function getMenuByType(partnerId: string, menuType: MenuType): Promise<any> {
  return API.get(API_NAME, `/partners/${partnerId}/menus?menuType=${menuType}`, { headers: await getHeaders() });
}

export async function getBuildingById(id: string): Promise<any> {
  return API.get(API_NAME, `/buildings/${id}`, { headers: await getHeaders() });
}

export async function getApiMe(user): Promise<any> {
  return API.get(API_NAME, '/me', { headers: await getHeaders(user.xa) });
}

export async function updateApiMe(user): Promise<any> {
  return API.put(API_NAME, '/me', { body: { email: user.email, name: user.name }, headers: await getHeaders(user.xa) });
}

export async function listMeOrders(auth): Promise<any> {
  return API.get(API_NAME, '/me/orders', { headers: await getHeaders(auth.token) });
}

export async function placeOrder(order, auth): Promise<any> {
  return API.post(API_NAME, '/me/orders', { body: order, headers: await getHeaders(auth.token) });
}

export async function refreshSquare(partnerId: string): Promise<any> {
  return API.put(API_NAME, `/partners/${partnerId}/square/refresh`, { headers: await getHeaders() });
}

export async function connectPartnerSquare(
  auth: any,
  partnerId: string,
  code: string,
): Promise<any> {
  return API.post(API_NAME, `/partners/${partnerId}/square`, {
    headers: await getHeaders(auth.token),
    body: { code },
  });
}

export async function getEmailLink(body): Promise<any> {
  return API.post(API_NAME, '/partners/email-link', { body, headers: await getHeaders() });
}

export async function setScheduleMenu(
  auth: any,
  partnerId: string,
  scheduleId: number,
  selectedMenu: string,
  menuApproved: string,
): Promise<any> {
  return API.patch(API_NAME, `/partners/${partnerId}/schedules/${scheduleId}`, {
    headers: await getHeaders(auth.token),
    body: {
      selectedMenu,
      menuApproved,
    },
  });
}

export async function setScheduleTax(
  auth: any,
  partnerId: string,
  scheduleId: number,
  selectedTax: any,
): Promise<any> {
  return API.patch(API_NAME, `/partners/${partnerId}/schedules/${scheduleId}`, {
    headers: await getHeaders(auth.token),
    body: {
      taxId: selectedTax.id,
      taxPercentage: selectedTax.tax_data.percentage,
      taxInclusionType: selectedTax.tax_data.inclusion_type,
    },
  });
}

export async function checkInOutForEvent(
  auth: any,
  partnerId: string,
  scheduleId: number,
  body,
): Promise<any> {
  return API.patch(API_NAME, `/partners/${partnerId}/schedules/${scheduleId}`, {
    headers: await getHeaders(auth.token),
    body,
  });
}

export async function getEventOrders(
  auth: any,
  partnerId: string,
  scheduleId: number,
): Promise<{ createDate: string, orderNumFormatted: number, order: Order }[]> {
  return API.get(API_NAME, `/partners/${partnerId}/schedules/${scheduleId}/orders`, {
    headers: await getHeaders(auth.token),
  });
}

export async function setPartnerSquareLocation(
  auth: any,
  partnerId: string,
  locationId: string,
): Promise<any> {
  return API.patch(API_NAME, `/partners/${partnerId}`, {
    headers: await getHeaders(auth.token),
    body: {
      locationId,
    },
  });
}

export async function setOrderStatus(
  order, status,
): Promise<any> {
  return API.patch(API_NAME, `/partners/${order.partnerId}/schedules/${order.scheduleId}/orders/${order.id}/status/${status}`, {
    headers: await getHeaders(),
  });
}

export async function updateSchedulePartnerMenu(
  schedulePartner, body: { enabledItem?: string, disabledItem?: string },
): Promise<any> {
  return API.patch(API_NAME, `/partners/${schedulePartner.food_truck}/schedules/${schedulePartner.schedule}`, {
    headers: await getHeaders(),
    body,
  });
}

export async function addMenu(
  partnerId: string, name: string, items: MenuItem[],
): Promise<Menu> {
  return API.post(API_NAME, `/partners/${partnerId}/menus`, {
    headers: await getHeaders(),
    body: { name, items },
  });
}

export async function updateMenu(
  partnerId: string, menuId: string, name: string, status: MenuStatus, items: MenuItem[],
): Promise<Menu> {
  return API.patch(API_NAME, `/partners/${partnerId}/menus/${menuId}`, {
    headers: await getHeaders(),
    body: { name, status, items },
  });
}

export async function listMenus(
  partnerId: string,
): Promise<Menu[]> {
  return API.get(API_NAME, `/partners/${partnerId}/menus`, {
    headers: await getHeaders(),
  });
}

export async function setDefaultMenu(partnerId: string, menuId: string): Promise<any> {
  return API.patch(API_NAME, `/partners/${partnerId}`, {
    headers: await getHeaders(),
    body: { defaultMenu: menuId },
  });
}
