/* eslint-disable */
import React, { useReducer } from 'react';
import * as firebase from 'firebase/app';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { authContext as AuthContext } from './authContext';
import authReducer from './authReducer';
import { getApiMe, updateApiMe, getEmailLink } from '../../common/api-client';
import {
  INIT_AUTH, AUTH_LOADING, SET_AUTH, SET_ME, SET_IS_SIGN_IN,
} from '../../common/dispatch';
const AuthState = (props: { children: any }) => {
  const initialState: any = {
    appLoading: true,
    authLoading: false,
    meLoading: false,
    authInit: false,
    isSignIn: false,
    step: 0,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const getMe = async (user: FirebaseAuth) => {
    return await getApiMe(user);
  };

  const initAuth = async () => {
    dispatch({ type: INIT_AUTH });
    try {
      firebase.auth().onAuthStateChanged(async (auth) => {
        dispatch({ type: AUTH_LOADING });
        if (auth) {
          dispatch({
            type: SET_AUTH,
            payload: auth,
          });

          const me = await getMe(auth);

          dispatch({
            type: SET_ME,
            payload: me,
          });

          dispatch({
            type: SET_IS_SIGN_IN,
            payload: false,
          });
        } else {
          dispatch({
            type: SET_AUTH,
            payload: { isAnonymous: true },
          });
          dispatch({ type: SET_ME, payload: null, });
        }
      });
      signInWithLink();
    } catch (err) {
      message.error(err);
    }
  };

  const signInWithLink = () => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      dispatch({ type: SET_IS_SIGN_IN, payload: true });
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      firebase.auth().signInWithEmailLink(email!, window.location.href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }

  const getSignInEmailLink = async (body: { email: string }) => await getEmailLink(body);

  const setMe = (user) => {
    dispatch({
      type: SET_ME,
      payload: user,
    });
  };

  const signOutUser = () => {
    firebase.auth().signOut().then(() => {
      dispatch({
        type: SET_AUTH,
        payload: { isAnonymous: true },
      });
      dispatch({
        type: SET_ME,
        payload: null,
      });
      message.success('You\'ve been successfully signed out!');
    });
  };

  interface FirebaseAuth {
    // TODO
  }

  const updateMe = async (user: FirebaseAuth) => updateApiMe(user);

  const { children } = props;

  return (
    <AuthContext.Provider
      value={{
        initAuth,
        signInWithLink,
        signOutUser,
        authInit: state.authInit,
        appLoading: state.appLoading,
        authLoading: state.authLoading,
        meLoading: state.meLoading,
        isSignIn: state.isSignIn,
        auth: state.auth,
        me: state.me,
        setMe,
        updateMe,
        getMe,
        getSignInEmailLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;

AuthState.propTypes = {
  children: PropTypes.shape({}),
};

AuthState.defaultProps = {
  children: {},
};
