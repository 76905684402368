import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';

// eslint-disable-next-line no-unused-vars
import { AuthContext } from '../../common/types';
import { authContext as _authContext } from '../../contexts/auth/authContext';

const Nav = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { auth, me } = authContext;
  const signOutUser = authContext.signOutUser!;

  return (
    <div className="nav">
      {
        me && (
          <Link to="/">
            <img
              className="nav-logo"
              src={`${process.env.PUBLIC_URL}/assets/logo.png`}
              alt="logo"
            />
          </Link>
        )
      }

      <div style={{ display: 'flex' }}>
        {
          me
            ? (
              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item key="0">User</Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="1">{`firebase: ${auth.email || 'Anon'}`}</Menu.Item>
                    <Menu.Item key="2">{`dynamo:  ${me.firstName} ${me.lastName}`}</Menu.Item>
                    <Menu.Divider />
                    {auth.isAnonymous ? null : (
                      <Menu.Item key="3" onClick={() => signOutUser()}>Sign Out</Menu.Item>
                    )}
                  </Menu>
                )}
                trigger={['click']}
              >
                <UserOutlined className="user-button" />
              </Dropdown>
            ) : (<></>)
        }
      </div>
    </div>
  );
};

export default Nav;
