import { GET_PARTNER_REQUEST, GET_PARTNER_SUCCESS } from '../../common/dispatch';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_PARTNER_REQUEST: {
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      };
    }
    case GET_PARTNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        partner: action.payload,
      };
    default:
      return state;
  }
};
