import React, { useContext, useEffect } from 'react';
import { Spin } from 'antd';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from '../../common/types';
import { authContext as _authContext } from '../../contexts/auth/authContext';

const Loading: React.FC<{}> = () => {
  const authContext: AuthContext = useContext(_authContext);
  const initAuth = authContext.initAuth!;
  const { authInit } = authContext;

  useEffect(() => {
    if (!authInit) {
      initAuth();
    }
  }, []);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '40vh',
    }}
    >
      <Spin />
    </div>
  );
};

export default Loading;
