import React from 'react';
import Amplify from 'aws-amplify';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { BrowserRouter as Router, Route as PublicRoute, Switch } from 'react-router-dom';
import config from './config';
import AuthState from './contexts/auth/AuthState';
import Route from './components/_common/Route';
import Nav from './components/_common/Nav';
import './App.css';
import './App.scss';
import PartnerState from './contexts/partner/PartnerState';
import SquareCallback from './components/square-oauth';
import IndexPage from './components/index';
import Login from './components/_common/Login';

Amplify.configure(config.aws);

firebase.initializeApp(config.firebase);

function App() {
  return (
    <AuthState>
      <PartnerState>
        <Router>
          <Nav />
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route path="/callback/square" component={SquareCallback} />
            <PublicRoute exact path="/login" component={Login} />
          </Switch>
        </Router>
      </PartnerState>
    </AuthState>
  );
}

export default App;
