import {
  INIT_AUTH, AUTH_LOADING, SET_AUTH, SET_ME, SET_IS_SIGN_IN,
} from '../../common/dispatch';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case INIT_AUTH:
      return {
        ...state,
        authInit: true,
      };
    case AUTH_LOADING:
      return {
        ...state,
        authLoading: true,
      };
    case SET_IS_SIGN_IN:
      return {
        ...state,
        isSignIn: action.payload,
      };
    case SET_AUTH:
      return {
        ...state,
        authLoading: false,
        meLoading: true,
        auth: {
          id: action.payload.uid,
          phoneNumber: action.payload.phoneNumber,
          email: action.payload.email,
          token: action.payload.xa,
          isAnonymous: action.payload.isAnonymous,
        },
      };
    case SET_ME:
      return {
        ...state,
        meLoading: false,
        appLoading: false,
        me: action.payload,
      };
    default:
      return state;
  }
};
