import React from 'react';

import {
  Modal, List, Typography,
} from 'antd';
import { Order } from '../../common/types';

interface Props {
  detail?: {
    orderNumFormatted: number,
    order: Order,
  }
  visible: boolean,
  onCancel: any,
}

const { Title } = Typography;

const OrderDetail = ({ detail, visible, onCancel }: Props) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Modal
      title={
        (
          <div>
            Order
            <span> </span>
            {detail && detail.orderNumFormatted}
          </div>
        )
      }
      visible={visible}
      onCancel={onCancel}
      onOk={onCancel}
    >
      {detail
        && (
          <List
            // bordered
            dataSource={detail.order.line_items}
            itemLayout="vertical"
            footer={
              (
                <Title level={4}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      Total
                    </div>
                    <div>
                      {formatter.format(detail.order.total_money.amount / 100)}
                    </div>
                  </div>
                </Title>
              )
            }
            rowKey="uid"
            renderItem={(item: any) => (
              <List.Item>
                <Typography.Text strong>

                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{`${item.name} ${item.variation_name ? `(${item.variation_name})` : ''}`}</div>
                    <div>{formatter.format(item.total_money.amount / 100)}</div>
                  </div>
                </Typography.Text>
                <List.Item.Meta
                  description={
                    (
                      <div>
                        {item.modifiers && item.modifiers.map((m) => (
                          <div key={m.uid}>
                            {`${m.name} ${m.total_price_money.amount ? `+ ${formatter.format(m.total_price_money.amount / 100)}` : ''}`}
                          </div>
                        ))}
                      </div>
                    )
                  }
                />
              </List.Item>
            )}
          />
        )}
    </Modal>
  );
};

export default OrderDetail;
