import React, { useContext } from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { Tabs } from 'antd';

import AuthContext from '../../contexts/auth/authContext';
import { AuthContext as AuthContextType } from '../../common/types';
import PartnerTab from './partner-tab';

interface TabProps {
  name: string,
  id: string,
}

const IndexPage = ({ location, history }: {
  match?: { params: { buildingId, scheduleId?} },
} & RouteChildrenProps) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const tabs: TabProps[] = authContext.me
    ? authContext.me.partners.map((el) => ({
      name: el.partnerName || el.partner?.display_name,
      id: el.partnerId,
    }))
    : [];

  const urlParams = new URLSearchParams(location.search);
  const defaultPartnerTab = urlParams.get('partner') || '';
  const defaultTab = urlParams.get('t') || '';

  return (
    <main
      className="index-root"
    >
      <Tabs
        onChange={(id: string) => {
          urlParams.set('partner', id);
          history.replace(`${location.pathname}?${urlParams.toString()}`);
        }}
        defaultActiveKey={`${defaultPartnerTab}`}
      >
        {tabs.map((tab) => (
          <Tabs.TabPane
            tab={tab.name}
            key={tab.id}
          >
            <PartnerTab
              partnerId={tab.id}
              defaultTab={defaultTab}
              location={location}
              history={history}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </main>
  );
};

export default IndexPage;
