import React, { useState, useContext } from 'react';
import { captureException } from '@sentry/react';
import { nanoid } from 'nanoid';
import { useLocation } from 'react-router-dom';
import { List, Button } from 'antd';

import AuthContext from '../contexts/auth/authContext';
import { AuthContext as AuthContextType, MerchantLocation } from '../common/types';
import * as apiClient from '../common/api-client';
import config from '../config';

enum Status { LOAD, LOCATION_CHOICE, SUCCESS, FAIL }

const permissions = [
  'MERCHANT_PROFILE_READ',
  'PAYMENTS_WRITE',
  'PAYMENTS_READ',
  'ITEMS_READ',
  'ITEMS_WRITE',
  'ORDERS_READ',
  'ORDERS_WRITE',
  'PAYMENTS_READ',
  'PAYMENTS_WRITE',
  'PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS',
  'CUSTOMERS_WRITE',
  'CUSTOMERS_READ',
];

export const onClickSquareConnect = (partnerId) => {
  const state = btoa(JSON.stringify({
    csrf: nanoid(64),
    partnerId,
  }));
  window.sessionStorage.setItem('square-oauth-state', state);
  const url = `${config.square.endpoint}/oauth2/authorize`
    + `?client_id=${config.square.clientId}&state=${state}`
    + `&scope=${permissions.join('+')}`;
  window.location.assign(url);
};

const statusMessage = (status: Status): string => {
  switch (status) {
    case Status.LOAD:
      return 'Loading...';
    case Status.LOCATION_CHOICE:
      return 'Please choose a location to use with this partner. You can reconnect if needed.';
    case Status.SUCCESS:
      return 'Success! Redirecting...';
    case Status.FAIL:
      return 'Failed to link account';
    default:
      return '';
  }
};

const SquareCallbackHandler = () => {
  const [status, setStatus] = useState<Status>(Status.LOAD);
  const [partnerId, setPartnerId] = useState<string>();
  const [locations, setLocations] = useState<MerchantLocation[]>([]);
  const authContext: AuthContextType = useContext(AuthContext);
  const urlLocation = useLocation();
  const { me } = authContext;

  const finish = () => {
    setStatus(Status.SUCCESS);
    setTimeout(() => {
      window.location.assign('/');
    }, 2000);
  };

  // This needs to poll b/c there's a delay in the
  // partnersendpoint returning new merchant info
  const checkMerchantData = async (forId: string) => {
    try {
      const partner = await apiClient.getPartnerById(
        authContext.auth,
        forId,
      );

      if (partner.merchant && partner.merchant.locations) {
        if (partner.merchant.locations.length > 1) {
          setLocations(partner.merchant.locations);
          setStatus(Status.LOCATION_CHOICE);
        } else {
          finish();
        }
      } else {
        setTimeout(checkMerchantData, 1000, forId);
      }
    } catch (err) {
      setStatus(Status.FAIL);
      captureException(err);
    }
  };

  const setMerchantChoice = async (locationId: string) => {
    try {
      setStatus(Status.LOAD);
      await apiClient.setPartnerSquareLocation(
        authContext.auth,
        partnerId!,
        locationId,
      );
      finish();
    } catch (err) {
      setStatus(Status.FAIL);
      captureException(err);
    }
  };

  const handleCallback = async () => {
    const urlParams = new URLSearchParams(urlLocation.search);
    const code = urlParams.get('code');

    if (!me) {
      return;
    }

    if (!code) {
      setStatus(Status.FAIL);
      return;
    }

    const expectedStr = window.sessionStorage.getItem('square-oauth-state');
    const returnedStr = urlParams.get('state');

    window.sessionStorage.removeItem('square-oauth-state');

    if (!expectedStr || !returnedStr) {
      setStatus(Status.FAIL);
      return;
    }

    const expected = JSON.parse(atob(expectedStr));
    const returned = JSON.parse(atob(returnedStr));

    if (expected.csrf !== returned.csrf) {
      setStatus(Status.FAIL);
      return;
    }

    if (expected.partnerId !== returned.partnerId) {
      setStatus(Status.FAIL);
      return;
    }

    try {
      await apiClient.connectPartnerSquare(
        authContext.auth,
        returned.partnerId,
        code,
      );
      // Check after a delay b/c there's a delay in the
      // partners endpoint returning new merchant info
      setTimeout(checkMerchantData, 1000, returned.partnerId);
      setPartnerId(returned.partnerId);
    } catch (err) {
      setStatus(Status.FAIL);
      captureException(err);
    }
  };
  React.useEffect(() => { handleCallback(); }, [me]);

  return (
    <main className="oauth-handler-root">
      <h1>Square Connect</h1>
      <p className="oauth-handler-status">{statusMessage(status)}</p>
      {status === Status.LOCATION_CHOICE && (
        <div className="oauth-handler-location-select">
          <List
            itemLayout="horizontal"
            dataSource={locations}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[(
                  <Button
                    onClick={() => setMerchantChoice(item.id)}
                  >
                    Connect
                  </Button>
                )]}
              >
                <div>
                  <h2><b>{item.name}</b></h2>
                  <p>{item.address.address_line_1}</p>
                </div>
              </List.Item>
            )}
          />
        </div>
      )}
    </main>
  );
};

export default SquareCallbackHandler;
